<template>
  <div>
    <div>
      <div style="display: inline-block; float: right; cursor: pointer">
        <el-button-group class="card-toggle-table">
          <el-tooltip v-if="cardType" class="item" effect="dark" content="切换成表格" placement="top-start">
            <el-button size="mini" plain icon="el-icon-s-grid" @click="toggle" />
          </el-tooltip>
          <el-tooltip v-else class="item" effect="dark" content="切换成卡片" placement="top-start">
            <el-button size="mini" plain icon="el-icon-bank-card" @click="toggle" />
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="批量导入" placement="top-start">
            <el-button size="mini" @click="importBookButton" plain icon="el-icon-folder-add" style="margin: 0px 2px;" />
          </el-tooltip>

        </el-button-group>
      </div>
      <el-button @click="addRecipeButton" >新增</el-button>
      <el-button @click="quickFoodButton">快速新增</el-button>
    </div>
    <div>
      <el-form :model="queryParams" layout="inline" :inline="true">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="书名" prop="name">
              <el-input v-model="queryParams.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-button type="primary" @click="xunLikeBooksButtonList">搜索</el-button>
            <el-button type="primary">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 如果cardType为true,则显示卡片风格 -->
    <el-row v-if="cardType" style="margin: 5px 0px">
      <el-col v-for="item in roles" :key="item.id" :span="5" style="margin-bottom: 10px" :offset="1">
        <el-card>
          <div slot="header" class="clearfix">
            <!-- <i class="el-icon-user" /> -->
            <span style="margin-left: 5px">
              {{ item.name }}-({{ item.code }})
            </span>
            <div style="display: inline-block; float: right; cursor: pointer" @click="doEdit(item)">
              <el-tooltip effect="dark" content="编辑角色" placement="top">
                <i class="el-icon-edit-outline" style="margin-left: 15px" />
              </el-tooltip>
            </div>
          </div>
          <div @dblclick="handleDoubleClick">
            <el-image src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-image>
            <ul class="role-info">
              <li>
                <div class="role-left">描述信息：{{ item.description }}</div>
              </li>
              <li>
                <div class="role-left">信息状态:{{ item.infoStatus }}</div>
              </li>
            </ul>
          </div>
          <!-- <div style="display: inline-block; float: left; cursor: pointer"
            @click="doAssignPemission(item.id, item.roleName)">
            <el-tooltip effect="dark" content="权限分配" placement="bottom">
              <i class="el-icon-menu" />
            </el-tooltip>
          </div>
          <div style="display: inline-block; float: right; cursor: pointer" @click="doDelete(item.id)">
            <el-tooltip effect="dark" content="加入计划" placement="bottom">
              <i class="el-icon-delete" style="margin-left: 15px" />
            </el-tooltip>
          </div> -->
          <!-- <div style="display: inline-block; float: right; cursor: pointer" @click="doDelete(item.id)">
            <el-tooltip effect="dark" content="查看分析" placement="bottom">
              <i class="el-icon-delete" style="margin-left: 15px" />
            </el-tooltip>
          </div> -->
          <!-- <div style="display: inline-block; float: right; cursor: pointer" @click="doDelete(item.id)">
            <el-tooltip effect="dark" content="分享" placement="bottom">
              <i class="el-icon-delete" style="margin-left: 15px" />
            </el-tooltip>
          </div> -->
          <div style="display: inline-block; float: right; cursor: pointer" @click="doDelete(item.id)">
            <el-tooltip effect="dark" content="删除" placement="bottom">
              <i class="el-icon-delete" style="margin-left: 15px" />
            </el-tooltip>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <!-- 如果cardType为false则显示列表风格 -->
    <el-table v-else ref="table" :data="roles" style="width: 100%; font-size: 12px"
      @selection-change="handleSelectionChange">
      <el-table-column prop="code" label="编码" />
      <el-table-column prop="name" label="书名" />


      <el-table-column :show-overflow-tooltip="true" width="150" prop="author" label="作者" />
      <!-- 
            <el-table-column :show-overflow-tooltip="true" prop="createTime" width="155" label="创建时间">
                <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column> -->
      <el-table-column prop="description" label="描述信息" />
      <el-table-column prop="infoStatus" label="信息状态" />
      <el-table-column label="操作" width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="text" round @click="doEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="text" round @click="doDelete(scope.row.id)">删除</el-button>
          <!-- <el-button size="mini" type="text" round
            @click="doAssignPemission(scope.row.id, scope.row.roleName)">分配权限</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <Recipde ref="recipdeModules" />
    <quickFood ref="quickFoodModules" />
    <importBook ref="importBookModules" />
  </div>
</template>
<script>
import { listBookApi } from "@/api/life/book/xunLikeBooks";
import Recipde from "./modules/addBook.vue";
import quickFood from "./modules/quickFood.vue";
import importBook from "./modules/importBook.vue";
export default {
  name: "name",
  components: {
    Recipde,
    quickFood,
    importBook
  },
  data() {
    return {
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        name: ''
      },
      cardType: true,
      // 角色列表
      roles: [
      ],
    };
  },
  created() {
    this.xunLikeBooksButtonList();
  },
  methods: {
    // 点击切换风格
    toggle() {
      this.cardType = !this.cardType;
    },
    //点击事件
    handleClick() {
      console.log("======");
    },
    //双击事件
    handleDoubleClick() {
      alert("您双击了这个div元素！");
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    addRecipeButton() {
      this.$refs.recipdeModules.show("", 1);
    },
    importBookButton() {
      this.$refs.importBookModules.show("", 1);
    },
    quickFoodButton() {
      this.$refs.quickFoodModules.show("", 1);
    },
    xunLikeBooksButtonList() {
      listBookApi(this.queryParams).then(res => {
        this.roles = res.result.records;
      })
    },
    doEdit(row){
      console.log('row',row)
      this.$refs.recipdeModules.show(row, 2);
    },
    doDelete(row){

    }
  },
};
</script>